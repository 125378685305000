require('./bootstrap');
import { Modal, Collapse } from 'bootstrap';

const loaderSpinner = document.getElementById('loader-spinner');
const loader = new Modal(loaderSpinner, {
  keyboard: false
});

(function () {
  'use strict'

  let interest = 0
  axios.get('/interest').then(function(response) {
    interest = response.data
  }).catch(function (error) {
    console.log(error);
  })

  const sustainabilityChecked = document.getElementById('wish-10');
  const sustainabilityCheckboxes = document.getElementById('sustainability-checkboxes')
  if (sustainabilityCheckboxes) {
    const sustainabilityCheckbox = sustainabilityCheckboxes.querySelectorAll('.cbs-2')
    const otherSustainability = sustainabilityCheckboxes.querySelector('.other');
    sustainabilityChecked.addEventListener('change', e => {
      const collapseSust = new Collapse(sustainabilityCheckboxes, {
        toggle: false
      })
      if (e.target.checked) {
        /* sustainabilityCheckboxes.removeAttribute('hidden') */
        collapseSust.show()
      } else {
        /* sustainabilityCheckboxes.setAttribute('hidden', true) */
        collapseSust.hide()
        otherSustainability.setAttribute('hidden', true)
        for (let i = 0; i < sustainabilityCheckbox.length; i++) {
          sustainabilityCheckbox[i].checked = false;
        }
      }
    })
  }

  const withOther = document.querySelectorAll('.with-other')
  for (let i = 0; i < withOther.length; i++) {
    const checkboxesWithOther = withOther[i].querySelector('input[value="Anders namelijk:"]');
    const otherField = withOther[i].querySelector('.other');
    const field = otherField.querySelector('input')
    checkboxesWithOther.addEventListener('change', el => {
      if (el.target.checked) {
        otherField.removeAttribute('hidden');
      } else {
        otherField.setAttribute('hidden', true);
        field.value = ''
      }
    })
  }

  const forms = document.querySelectorAll('.needs-validation');
  const alerts = document.querySelectorAll('.alert');
  const alertsuccess = document.querySelector('.alert-success');
  const alertError = document.querySelector('.alert-danger');
  const alertBlocks = document.getElementById('form-alerts');

  Array.prototype.slice.call(forms)
    .forEach(function (form) {
      form.addEventListener('submit', function (event) {
        event.preventDefault();
        event.stopPropagation();
        for (let a = 0; a < alerts.length; a++) {
          alerts[a].classList.remove('d-flex');
        }

        const buildChecked = document.querySelectorAll('input[name="tobuild"]:checked')
        const sustainabltyChecked = document.querySelectorAll('input[name="sustainability"]:checked')
        const otherWishChecked = document.querySelectorAll('input[name="other_wish"]:checked')
        
        if (form.checkValidity()) {
          loader.show();
          const url = form.action;
          const formData = new FormData(form);
          let build_r = []
          let sustain_r = []
          let other_r = []
          for (let b = 0; b < buildChecked.length; b++) {
            build_r.push(buildChecked[b].value);
          }
          if (build_r.length) {
            formData.append ('build_wishes', build_r);
          }
  
          for (let s = 0; s < sustainabltyChecked.length; s++) {
            sustain_r.push(sustainabltyChecked[s].value);
          }
          if (sustain_r.length) {
            formData.append ('sustainability_wishes', sustain_r);
          }
  
          for (let o = 0; o < otherWishChecked.length; o++) {
            other_r.push(otherWishChecked[o].value);
          }
          if (other_r.length) {
            formData.append ('other_wishes', other_r);
          }
          formData.append ('mortgage_interest_rate', interest);
          let object = {};
          formData.forEach((value, key) => object[key] = value);
          axios.post(url, object)
          .then(function (response) {
            loader.hide();
            console.log(response);
            if (response.data.error === false) {
              /* gtag('event', 'conversion', {'send_to': 'AW-838941207/4jD_CMGXsacBEJf0hJAD'}); 
              alertsuccess.classList.add('d-flex');
              alertsuccess.querySelector('.message').innerHTML = response.data.success; */
              window.location.href = '/bedankt?form=complete'
            } else {
              alertError.classList.remove('d-flex');
              alertError.querySelector('.message').innerHTML = response.data.error + response.data.validate;
            }
            alertBlocks.scrollIntoView({behavior: "smooth"});
          })
          .catch(function (error) {
            loader.hide();
            alertError.classList.remove('d-flex');
            alertError.querySelector('.message').innerHTML = error.message;
            alertBlocks.scrollIntoView({behavior: "smooth"});
          });
        }

        form.classList.add('was-validated')
      }, false)
    })
})()